.container {
  width: 100%;
  max-width: 95%;
  margin: 20px auto;
  padding: 20px;
  padding-top: 6rem; /* Add padding top to offset the header height */
  background-color: rgba(255, 255, 255, 0); /* Using rgba for transparency */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-sizing: border-box; /* Include padding in the element's total width */
}

.carouselContainer {
  margin: 20px auto;
  max-width: 600px;
}

.carouselContainer img {
  width: 100%;
  border-radius: 8px;
}

.textContainer {
  margin: 20px auto;
  max-width: 600px;
  text-align: center;
}

h1, h3, p {
  margin-bottom: 20px;
}
