.header {
  width: 100%; /* Ensure the header spans the full viewport width */
  background: #333;
  color: white;
  text-align: center; /* Center text inside the header */
  padding: 1.5rem; /* Adjust padding as needed */
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow issues */
  margin: 0;
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  height: 7.5rem; /* Adjust according to actual height of the header */
}

  
  .logo {
    display: flex;
    justify-content: center; /* Center the logo */
    align-items: center;
    margin-bottom: 0.5rem;
    white-space: nowrap; /* Prevent logo text from wrapping */
  }
  
  .logo img {
    height: 50px;
    margin-right: 0px;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; /* Center the nav items horizontally */
    gap: 5px; /* Adjust spacing between nav items */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  nav ul li {
    display: inline;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    padding: 0.5rem;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }
  