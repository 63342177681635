.carouselContainer {
    margin: 20px auto;
    max-width: 600px;
  }
  
  .carouselContainer img {
    width: auto;
    max-height: 700px;
    border-radius: 8px;
  }

  .textContainer {
    margin: 10px auto;
    max-width: 80%;
    text-align: center;
  }
  
  h1, h2, h3, p {
    margin-bottom: 20px;
  }
  