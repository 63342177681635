footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
}

.footer p {
    margin: 0 0 2px;
}

.footer .social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust spacing between icons */
}

.footer .social-icons li {
    display: inline;
}

.footer .social-icons li a {
    text-decoration: none;
    color: inherit;
    font-size: 1.0em; /* Adjust icon size */
}

.footer .social-icons li a:hover {
    color: #555; /* Change color on hover if desired */
}
